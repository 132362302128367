import React, { useEffect, useState } from 'react';

import Logo from './Logo';
import Menu from './Menu';
import Social from '../common/Social';

import * as cls from './Header.module.scss';

import MenuSvg from '../../images/icons/inline/menu.svg';
import CrossSvg from '../../images/icons/inline/cross.svg';

export default function Header() {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  const handelMenuItemClick = () => {
    if (isShowMenu) setIsShowMenu(false);
  };

  return (
    <div className={`${cls.container} ${scrolled ? cls.scrolled : ''}`}>
      <header className={`${cls.header} ${isShowMenu ? cls.active : ''}`}>
        <Logo />

        <button
          type='button'
          className={cls.menuOpener}
          onClick={handleClickMenu}
        >
          {isShowMenu ? (
            <CrossSvg
              className={cls.menuOpenerCrossIcon}
              role='img'
              aria-label='close'
            />
          ) : (
            <MenuSvg
              className={cls.menuOpenerIcon}
              role='img'
              aria-label='menu'
            />
          )}
        </button>

        <div className={cls.menu}>
          <Menu onItemClick={handelMenuItemClick} />

          <div className={cls.divider} />

          <Social className={cls.social} />
        </div>
      </header>
    </div>
  );
}
