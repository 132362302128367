// extracted by mini-css-extract-plugin
export var container = "Header-module--container--3KhaC";
export var header = "Header-module--header--1Qt4T";
export var active = "Header-module--active--1N9yZ";
export var menu = "Header-module--menu--2CFMI";
export var social = "Header-module--social--Z5gkn";
export var divider = "Header-module--divider--2Hlei";
export var menuOpener = "Header-module--menu-opener--1Liqi";
export var menuOpenerIcon = "Header-module--menu-opener-icon--1L4zZ";
export var menuOpenerCrossIcon = "Header-module--menu-opener-cross-icon--2f8Dt";
export var scrolled = "Header-module--scrolled--2RXkH";