import FlowersSvg from '../../../images/icons/inline/flowers.svg';
import ViewSvg from '../../../images/icons/inline/view.svg';
import DownloadSvg from '../../../images/icons/inline/download.svg';
import LinkedinSvg from '../../../images/icons/inline/linkedin.svg';
import TwitterSvg from '../../../images/icons/inline/twitter.svg';
import FacebookSvg from '../../../images/icons/inline/facebook.svg';
import MediumSvg from '../../../images/icons/inline/medium.svg';
import Step1Svg from '../../../images/icons/inline/step-1.svg';
import Step2Svg from '../../../images/icons/inline/step-2.svg';
import Step3Svg from '../../../images/icons/inline/step-3.svg';
import ArrowSvg from '../../../images/icons/inline/arrow.svg';
import ArrowOpenSvg from '../../../images/icons/inline/ArrowOpen.svg';

const IconsSvg = {
  Flowers: FlowersSvg,
  View: ViewSvg,
  Download: DownloadSvg,
  Linkedin: LinkedinSvg,
  Twitter: TwitterSvg,
  Facebook: FacebookSvg,
  Medium: MediumSvg,
  Step1: Step1Svg,
  Step2: Step2Svg,
  Step3: Step3Svg,
  Arrow: ArrowSvg,
  ArrowOpen: ArrowOpenSvg,
};

export default IconsSvg;
