import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { parse } from 'node-html-parser';

import IconsSvg from '../../common/IconsSvg';

import * as cls from './Social.module.scss';

function Item({ href, label, Icon }) {
  return (
    <li>
      <a className={cls.item} href={href}>
        <Icon className={cls.icon} role='img' aria-label={label} />
      </a>
    </li>
  );
}

export default function Social({ className = '' }) {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "landing" }) {
        content
      }
    }
  `);

  const content = parse(data.wpPage.content);

  return (
    <ul className={`${cls.social} ${className}`}>
      <Item
        href={content.querySelector('#social-linkedin').getAttribute('href')}
        label={content
          .querySelector('#social-linkedin')
          .getAttribute('data-icon')}
        Icon={
          IconsSvg[
            content.querySelector('#social-linkedin').getAttribute('data-icon')
          ]
        }
      />
      <Item
        href={content.querySelector('#social-twitter').getAttribute('href')}
        label={content
          .querySelector('#social-twitter')
          .getAttribute('data-icon')}
        Icon={
          IconsSvg[
            content.querySelector('#social-twitter').getAttribute('data-icon')
          ]
        }
      />
      <Item
        href={content.querySelector('#social-facebook').getAttribute('href')}
        label={content
          .querySelector('#social-facebook')
          .getAttribute('data-icon')}
        Icon={
          IconsSvg[
            content.querySelector('#social-facebook').getAttribute('data-icon')
          ]
        }
      />
      <Item
        href={content.querySelector('#social-medium').getAttribute('href')}
        label={content
          .querySelector('#social-medium')
          .getAttribute('data-icon')}
        Icon={
          IconsSvg[
            content.querySelector('#social-medium').getAttribute('data-icon')
          ]
        }
      />
    </ul>
  );
}
