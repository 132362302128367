import React from 'react';

import * as cls from './Logo.module.scss';

import LogoSvg from '../../../images/icons/inline/logo.svg';

export default function Logo() {
  return (
    <div>
      <a href='/#main'>
        <LogoSvg className={cls.img} role='img' aria-label='logo' />
      </a>
    </div>
  );
}
