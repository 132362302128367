import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from '../Header';
import Main from '../Main';
import Footer from '../Footer';

import * as cls from './Layout.module.scss';
import '../../theme/style.scss';

export default function Layout({ children, title, description }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <div className={cls.page}>
      <Helmet>
        <html lang='en' />
        <title>{title || data.site.siteMetadata.title}</title>
        <meta
          name='Description'
          content={description || data.site.siteMetadata.description}
        />
      </Helmet>
      <Header />
      <Main className={cls.main}>{children}</Main>
      <Footer />
    </div>
  );
}
