import React from 'react';

import Arrow from '../../../images/icons/inline/arrow.svg';

import * as cls from './Button.module.scss';

export default function Button({
  children,
  className = '',
  mode = 'base', // base | alt | link | menu | back
  ...props
}) {
  const TagName = props.href ? 'a' : 'button';

  return (
    <TagName
      className={`${cls.button} ${cls[mode]} ${className ?? ''}`}
      {...props}
    >
      {mode === 'back' && <Arrow className={cls.arrowIcon} />}
      {children}
    </TagName>
  );
}
