import React from 'react';

import * as cls from './Text.module.scss';

export default function Text({
  children,
  className = '',
  size = 'base', // base | big
  ...props
}) {
  return (
    <p className={`${cls.text} ${cls[size]} ${className}`} {...props}>
      {children}
    </p>
  );
}
