import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { parse } from 'node-html-parser';
import Logo from '../Header/Logo';
import Social from '../common/Social';
import Text from '../common/Text';

import * as cls from './Footer.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

export default function Footer({ className = '' }) {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: { eq: "landing" }) {
        content
      }
    }
  `);

  const content = parse(data.wpPage.content);

  return (
    <footer className={`${cls.footer} ${className}`}>
      <div className={cls.footerContent}>
        <div className={cls.logoWrapper}>
          <Logo />
          <div className={cls.earscLogoWrapper}>
            <div className={cls.earscLogoText}>Member of</div>
            <StaticImage
              src='../../images/content/earsc.png'
              alt='earsc'
              className={cls.earscLogo}
            />
          </div>
        </div>
        <Text className={cls.copyright}>
          {content.querySelector('#footer-copyright')?.text}{' '}
          <span>{new Date().getFullYear()}</span>©
        </Text>
        <nav>
          <Social className={cls.social} />
        </nav>
      </div>
    </footer>
  );
}
