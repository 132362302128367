import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { parse } from 'node-html-parser';

import Button from '../../common/Button';

import * as cls from './Menu.module.scss';

export default function Menu({ onItemClick }) {
  const data = useStaticQuery(graphql`
    query {
      allWpPost {
        nodes {
          slug
        }
      }
      wpPage(slug: { eq: "landing" }) {
        content
      }
    }
  `);

  const content = parse(data.wpPage.content);

  return (
    <nav className={cls.nav}>
      <ul className={cls.list}>
        {content.querySelectorAll('#header .menu-item').map((item, index) =>
          item.getAttribute('id') === 'header-blog' &&
          !data.allWpPost.nodes.length ? null : (
            <li className={cls.listItem} key={index}>
              <Button
                href={item.getAttribute('href')}
                mode={item.classList.contains('button') ? 'base' : 'menu'}
                aria-label={item.text}
                onClick={onItemClick}
              >
                {item.text}
              </Button>
            </li>
          )
        )}
      </ul>
    </nav>
  );
}
