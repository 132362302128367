import React from 'react';

import * as cls from './Main.module.scss';

export default function Main({ children, className = '', ...props }) {
  return (
    <main id='main' className={`${cls.main} ${className}`} {...props}>
      {children}
    </main>
  );
}
